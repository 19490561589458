<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { toBoolean } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UpdateLocationSettings',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        csOperatorFk: null,
        cityId: null,
        neighborhood: null,
        slotsAvailable: 0,
        active: false,
        openLocation: false,
        api: false,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      updateStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.inputs.csOperatorFk = this.location.csOperatorFk;
    this.inputs.cityId = this.location.cityFk;
    this.inputs.neighborhood = this.location.neighborhood;
    this.inputs.slotsAvailable = this.location.slotsAvailable;
    this.inputs.active = toBoolean(this.location.active);
    this.inputs.openLocation = toBoolean(this.location.openLocation);
    this.inputs.api = toBoolean(this.location.api);
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    async saveLocationSettings() {
      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: this.inputs,
      });

      if (!this.updateStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Settings successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Edit settings"
    class="UpdateLocationSettings"
    data-test-id="edit_settings-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        use-message
        element="settings"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12">
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              label="Status"
              class="mb-4 py-1 d-block"
              data-test-id="active-toggle"
              name="active"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.csOperatorFk"
              v-validate="{
                isRequired: true,
              }"
              :title="operator => operator.name"
              :index="ALGOLIA_INDEXES.csOperators"
              :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
              label="CS Operator*"
              placeholder="Select"
              class="w-100"
              path-value="id"
              name="operator"
            />
          </div>

          <div class="col-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.cityId"
              v-validate="{
                isRequired: true,
              }"
              :title="city => city.name"
              :index="ALGOLIA_INDEXES.cities"
              :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.cities })"
              label="City*"
              placeholder="Select"
              class="w-100"
              path-value="id"
              name="city"
            />
          </div>

          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.neighborhood"
              label="Neighborhood"
              placeholder="Enter"
              data-test-id="neighborhood-input"
              name="neighborhood"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.slotsAvailable"
              label="Available slots"
              placeholder="Enter"
              data-test-id="slots-input"
              name="slots"
              class="w-100"
            />
          </div>

          <div class="col-6">
            <ui-toggle
              :value="inputs.api"
              :text="inputs.api ? 'Visible' : 'Invisible'"
              label="Visibility"
              class="mb-4 py-1 d-block"
              data-test-id="visible-toggle"
              name="visibility"
              @changevalue="({ detail }) => inputs.api = detail"
            />
          </div>
          <div class="col-6">
            <ui-toggle
              :value="inputs.openLocation"
              :text="inputs.openLocation ? 'Yes' : 'No'"
              label="Open location"
              class="mb-4 py-1 d-block"
              data-test-id="open-toggle"
              name="open"
              @changevalue="({ detail }) => inputs.openLocation = detail"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="updateStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationSettings"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
