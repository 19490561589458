var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateLocationDevice",
        attrs: {
          header: { isClosable: true },
          title: "Edit device",
          "data-test-id": "edit_device-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.description,
                    "is-editing": "",
                    element: "device",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1 d-block",
                            attrs: {
                              value: _vm.inputs.active,
                              text: _vm.inputs.active ? "Active" : "Inactive",
                              "data-test-id": "active-toggle",
                              name: "active",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.active = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("PhoneInputComponent", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isValidPhone:
                                    _vm.PHONE_VALIDATIONS.isValidPhone,
                                },
                                expression:
                                  "{\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Phone number",
                              placeholder: "Enter phone",
                              "data-test-id": "phone",
                            },
                            model: {
                              value: _vm.inputs.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "phone", $$v)
                              },
                              expression: "inputs.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("MuiInputText", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Serial number",
                              placeholder: "Enter",
                              "data-test-id": "serial-input",
                              name: "serial",
                            },
                            model: {
                              value: _vm.inputs.serialNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "serialNumber", $$v)
                              },
                              expression: "inputs.serialNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.updateStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.saveLocationDevice },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }