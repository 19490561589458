var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "LocationSetupView" }, [
    _c("h1", { staticClass: "mb-2" }, [_vm._v(" Setup ")]),
    _c(
      "div",
      { staticClass: "LocationSetupView__sections" },
      [
        _c("LocationSettingsCard"),
        _c("LocationAddressCard"),
        _c("LocationDeviceCard"),
        _c("LocationDescriptionCard"),
        _c("LocationPicturesCard"),
        _vm.location.type !== _vm.LOCATION_TYPES.geofence
          ? [_c("LocationContactCard"), _c("LocationCompanyCard")]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }