var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UploadPictureModal",
        attrs: {
          header: { isClosable: true },
          title: "Upload picture",
          "data-test-id": "picture_upload-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.addMedia,
                    action: "upload picture",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: !_vm.isFormValid,
                          loading: _vm.addMediaStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.uploadPicture },
                      },
                      [_vm._v(" Upload ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v(" Picture* ")]
                  ),
                  _c(
                    "DragFileComponent",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.files",
                          value: {
                            isMaxSize: _vm.FILE_VALIDATIONS.isMaxSize,
                            isRequired: true,
                          },
                          expression:
                            "{\n            isMaxSize: FILE_VALIDATIONS.isMaxSize,\n            isRequired: true,\n          }",
                          modifiers: { files: true },
                        },
                      ],
                      attrs: {
                        "draggable-height": 230,
                        "show-preview": "",
                        "data-test-id": "file-input",
                      },
                      model: {
                        value: _vm.inputs.file,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "file", $$v)
                        },
                        expression: "inputs.file",
                      },
                    },
                    [
                      _c("template", { slot: "extraInformation" }, [
                        _c(
                          "span",
                          { staticClass: "d-block emobg-font-x-small mt-2" },
                          [_vm._v(" Maximum size 10 MB (JPG, PNG) ")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }