var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LocationDetailHeader",
      attrs: { "data-test-id": "location_detail-header" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "OverviewCollapse",
              {
                attrs: { id: "locationOverview" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "emobg-font-weight-semibold mr-3",
                              },
                              [_vm._v(" " + _vm._s(_vm.location.name) + " ")]
                            ),
                            _c("ui-badge", {
                              staticClass: "mr-2",
                              attrs: {
                                color: _vm.location.active
                                  ? _vm.COLORS.success
                                  : _vm.COLORS.danger,
                                text: _vm.location.active
                                  ? "Active"
                                  : "Inactive",
                                "data-test-id": "status-badge",
                              },
                            }),
                            _c("ui-badge", {
                              staticClass: "mr-2",
                              attrs: {
                                color: _vm.GRAYSCALE.ground,
                                text: _vm.location.openLocation
                                  ? "Open"
                                  : "Dedicated",
                                "data-test-id": "open_location-badge",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _vm.isOpenDoorsButtonVisible
                          ? _c(
                              "ui-button",
                              {
                                staticClass: "ml-3",
                                attrs: {
                                  loading: _vm.openDoorsStatus.LOADING,
                                  "data-test-id": "open_doors-button",
                                },
                                on: { clickbutton: _vm.openParkingDoors },
                              },
                              [
                                _c("ui-icon", {
                                  staticClass: "pr-2",
                                  attrs: { icon: _vm.ICONS.unlock },
                                }),
                                _vm._v(" Open doors "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("div", { staticClass: "pt-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md" },
                      [
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "mb-4",
                            attrs: { label: "Operator", "is-on-header": "" },
                          },
                          [
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.location.csOperator) + " "
                              ),
                            ]),
                            _c("p", { staticClass: "emobg-color-ink-light" }, [
                              _vm._v(
                                " City: " + _vm._s(_vm.location.city) + " "
                              ),
                            ]),
                            _c("p", { staticClass: "emobg-color-ink-light" }, [
                              _vm._v(
                                " Neighborhood: " +
                                  _vm._s(_vm.location.neighborhood) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c("ContentCellComponent", {
                          staticClass: "mb-4",
                          attrs: {
                            value: _vm.sentenceCase(_vm.location.type),
                            label: "Location type",
                            "is-on-header": "",
                            "data-test-id": "type-text",
                          },
                        }),
                        _c("ContentCellComponent", {
                          staticClass: "mb-4",
                          attrs: {
                            value: _vm.location.slotsAvailable,
                            label: "Available slots",
                            "is-on-header": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.get(_vm.location, "type") !==
                    _vm.LOCATION_TYPES.geofence
                      ? _c(
                          "div",
                          { staticClass: "col-md" },
                          [
                            _c("ContentCellComponent", {
                              staticClass: "mb-4",
                              attrs: {
                                value: _vm.get(
                                  _vm.location,
                                  "contractContact.name"
                                ),
                                label: "Contact name",
                                "is-on-header": "",
                              },
                            }),
                            _c(
                              "ContentCellComponent",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  label: "Contact email",
                                  "is-on-header": "",
                                },
                              },
                              [
                                _vm.get(_vm.location, "contractContact.email")
                                  ? _c(
                                      "CopyToClipboard",
                                      {
                                        attrs: {
                                          "data-test-id": "copy_email-button",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.get(
                                                _vm.location,
                                                "contractContact.email"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                          " "
                                      ),
                                    ],
                              ],
                              2
                            ),
                            _c(
                              "ContentCellComponent",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  label: "Contact phone number",
                                  "is-on-header": "",
                                },
                              },
                              [
                                _vm.get(_vm.location, "contractContact.phone")
                                  ? _c(
                                      "CopyToClipboard",
                                      {
                                        attrs: {
                                          "data-test-id": "copy_phone-button",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.get(
                                                _vm.location,
                                                "contractContact.phone"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                          " "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-4 emobg-border-radius-small emobg-border-color-ground-light emobg-border-1",
                          staticStyle: { "min-width": "302px" },
                        },
                        [
                          _c("GoogleMapComponent", {
                            attrs: {
                              markers: _vm.locationMarkers,
                              zones: _vm.locationZones,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }