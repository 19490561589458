var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "LocationsListView",
      attrs: { "data-test-id": "locations-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Locations ")]),
          _c(
            "ui-dropdown",
            {
              staticClass: "z-index-4",
              attrs: {
                color: _vm.COLORS.corporateSecondary,
                "text-color": _vm.GRAYSCALE.white,
                arrow: "",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "pl-3",
                  attrs: { slot: "trigger" },
                  slot: "trigger",
                },
                [_vm._v(" Create location ")]
              ),
              _c("ui-dropdown-actions", {
                attrs: { slot: "content", "data-test-id": "dropdown-list" },
                domProps: { actions: _vm.actions },
                slot: "content",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "locations",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.contentCells,
              facets: _vm.locationListFacets,
              filters: _vm.getOperatorFilter(),
              index: _vm.LOCATIONS_INDEX,
              "query-parameters": {
                attributesToRetrieve: _vm.attributesToRetrieve,
                restrictSearchableAttributes: _vm.restrictSearchableAttributes,
              },
              "table-config": _vm.contentCells,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isDeleteModalVisible
        ? _c("DeleteLocationModal", {
            attrs: {
              location: _vm.location,
              "on-delete-success": _vm.onFormSuccess,
              "data-test-id": "delete_location",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }