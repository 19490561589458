<script>
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getImageName } from '@emobg/web-utils';
import { TableComponent } from '@/components';
import { downloadFile } from '@/utils';
import { scopes } from '../../../store/LocationsModule';
import { PICTURES_SCHEMA } from './pictures.const';
import PreviewModal from './components/PreviewModal';
import DeleteModal from './components/DeleteModal';
import UploadPictureModal from './components/UploadPictureModal';

export default {
  name: 'LocationPicturesCard',
  components: {
    UploadPictureModal,
    DeleteModal,
    PreviewModal,
    TableComponent,
  },
  data() {
    return {
      isAddModalOpen: false,
      isDeleteModalOpen: false,
      isPreviewModalOpen: false,
      pictureToDelete: null,
      startIndex: 0,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      images: state => state.detail.data.images || [],
      imagesStatus: state => state.detail.STATUS,
    }),
    pictureSchema() {
      return PICTURES_SCHEMA(this.openPreviewModal);
    },
  },
  created() {
    this.scopes = scopes;
    this.pictureRowActions = [
      {
        label: 'Preview picture',
        labelClass: 'emobg-font-weight-semibold',
        action: this.openPreviewModal,
      },
      {
        label: 'Download picture',
        labelClass: 'emobg-font-weight-semibold',
        action: picture => downloadFile({ url: picture.url, name: getImageName(picture.url) }),
      },
      {
        label: 'Delete picture',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: picture => {
          this.pictureToDelete = picture;
          this.isDeleteModalOpen = true;
        },
      },
    ];
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    openPreviewModal(picture) {
      this.startIndex = findIndex(this.images, ({ uuid }) => uuid === picture.uuid);
      this.isPreviewModalOpen = true;
    },
    closeModal() {
      this.isAddModalOpen = false;
      this.isPreviewModalOpen = false;
      this.isDeleteModalOpen = false;
      this.pictureToDelete = null;
      this.startIndex = 0;
    },
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
  },
};
</script>
<template>
  <div class="LocationPicturesCard">
    <ui-card
      class="Pictures w-100 d-block"
      data-test-id="pictures_card"
    >
      <div class="d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center">
        <h3
          class="emobg-color-ink emobg-font-weight-semibold"
          data-test-id="title"
        >
          Pictures
        </h3>

        <ui-button
          :face="FACES.outline"
          @clickbutton="isAddModalOpen = true"
        >
          Upload picture
        </ui-button>
      </div>
      <div class="position-relative">
        <ui-loader
          v-if="imagesStatus.LOADING"
          absolute
          data-test-id="loader"
        />
        <TableComponent
          :schema="pictureSchema"
          :data="images"
          :row-actions="pictureRowActions"
          empty-label="No pictures yet"
          class="mt-3"
        />
      </div>
    </ui-card>
    <PreviewModal
      v-if="isPreviewModalOpen"
      :start-index="startIndex"
      @closeModal="closeModal"
    />

    <UploadPictureModal
      v-if="isAddModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />

    <DeleteModal
      v-if="isDeleteModalOpen"
      :picture="pictureToDelete"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
