<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { LOCATION_TYPES } from '@domains/Fleet/Locations/locations.const';
import { ContentCellComponent, EditButton } from '@/components';

import { scopes } from '../../../store/LocationsModule';
import UpdateLocationDevice from './components/UpdateLocationDevice';

export default {
  name: 'LocationDeviceCard',
  components: {
    UpdateLocationDevice,
    ContentCellComponent,
    EditButton,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
    this.LOCATION_TYPES = LOCATION_TYPES;
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
    closeModal() {
      this.isModalFormOpen = false;
    },
  },
};
</script>
<template>
  <div class="LocationDeviceCard">
    <ui-card
      v-if="get(location, 'type') === LOCATION_TYPES.parking"
      header="Device"
      class="Device w-100 position-relative d-block"
      data-test-id="device_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Device status"
                class="mb-4"
              >
                <ui-badge
                  :color="get(location, 'privateDevice.active') ? COLORS.success : GRAYSCALE.ground"
                  :text="get(location, 'privateDevice.active') ? 'Active' : 'Inactive'"
                  data-test-id="status-badge"
                />
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Phone"
                :value="get(location, 'privateDevice.phone')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Serial number"
                :value="get(location, 'privateDevice.serialNumber')"
                class="mb-4"
              />
            </div>
          </div>
        </div>

        <EditButton @click="isModalFormOpen = true" />
      </div>
    </ui-card>
    <UpdateLocationDevice
      v-if="isModalFormOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
