import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS } from '@emobg/vue-base';
import { sentenceCase } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import fleet from '@domains/Fleet/router/FleetRouterMap';
import PermissionLink from '@/components/PermissionLink/PermissionLink';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'name',
  'display_address',
  'neighborhood',
  'city',
  'type',
  'status',
  'cs_operator',
  'gps_lat',
  'gps_lng',
];

export const contentCells = [
  {
    title: 'ID',
    displayPriority: 1,
    minWidth: 60,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: fleet.locations.detail.index,
        params: {
          locationUuid: result.uuid,
        },
      },
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingLocations],
      text: `#${result.id}`,
    }),
  },
  {
    attributeName: 'name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 180,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: fleet.locations.detail.index,
        params: {
          locationUuid: result.uuid,
        },
      },
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingLocations],
      text: result.name,
    }),
  },
  {
    attributeName: 'display_address',
    title: 'Address',
    displayPriority: 1,
    minWidth: 125,
  },
  {
    attributeName: 'neighborhood',
    title: 'Neighborhood',
    displayPriority: 1,
    minWidth: 115,
  },
  {
    attributeName: 'city',
    title: 'City',
    displayPriority: 1,
    minWidth: 80,
  },
  {
    attributeName: 'type',
    title: 'Type',
    displayPriority: 2,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: status === 'active' ? COLORS.success : COLORS.danger,
    }),
  },
  {
    attributeName: 'cs_operator',
    title: 'Operator',
    displayPriority: 0,
    minWidth: 120,
  },
  {
    attributeName: 'gps_lat',
    title: 'Longitude',
    displayPriority: 0,
  },
  {
    attributeName: 'gps_lng',
    title: 'Latitude',
    displayPriority: 0,
  },
];

export const locationListFacets = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Neighborhood',
      attributeName: 'neighborhood',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'cs_operator',
    },
  },
];
