<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';
import { scopes } from '../../../store/LocationsModule';
import UpdateLocationContact from './components/UpdateLocationContact';

export default {
  name: 'LocationContactCard',
  components: {
    UpdateLocationContact,
    ContentCellComponent,
    EditButton,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
    closeModal() {
      this.isModalFormOpen = false;
    },
  },
};
</script>
<template>
  <div class="LocationContactCard">
    <ui-card
      header="Contact details"
      class="Contact w-100 position-relative d-block"
      data-test-id="Contact_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contact name"
                :value="get(location, 'contractContact.name')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contact phone number"
                :value="get(location, 'contractContact.phone')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contact email"
                :value="get(location, 'contractContact.email')"
                class="mb-4"
              />
            </div>

            <div class="col-12">
              <ContentCellComponent
                label="Internal remarks"
                :value="get(location, 'internalRemarks')"
                class="mb-4"
              />
            </div>
          </div>
        </div>

        <EditButton @click="isModalFormOpen = true" />
      </div>
    </ui-card>
    <UpdateLocationContact
      v-if="isModalFormOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
