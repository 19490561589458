import { DATE_FORMAT, FALLBACK_MESSAGE, reformatDateTime } from '@emobg/web-utils';
import { DocumentThumbnail } from '@/components';

export const PICTURES_SCHEMA = openPreviewModal => [
  {
    header: 'Upload date',
    template: picture => picture.updatedAt ? reformatDateTime(picture.updatedAt, DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Picture',
    component: DocumentThumbnail,
    props: picture => ({
      image: picture.url,
      onClickImage: () => {
        openPreviewModal(picture);
      },
    }),
  },
  {
    header: 'Name',
    template: picture => picture.name || FALLBACK_MESSAGE.dash,
  },
];
