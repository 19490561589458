<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';
import { scopes } from '../../../store/LocationsModule';
import UpdateLocationCompany from './components/UpdateLocationCompany';

export default {
  name: 'LocationCompanyCard',
  components: {
    UpdateLocationCompany,
    ContentCellComponent,
    EditButton,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
    closeModal() {
      this.isModalFormOpen = false;
    },
  },
};
</script>
<template>
  <div class="LocationCompanyCard">
    <ui-card
      header="Contract"
      class="CompanyContract w-100 position-relative"
      data-test-id="company_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Company"
                :value="get(location, 'contractCompany')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contract start"
                :value="get(location, 'contractStart')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contract end"
                :value="get(location, 'contractEnd')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Termination notice"
                :value="get(location, 'contractTerminationNotice') ? `${get(location, 'contractTerminationNotice')} month(s)` : null"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contract cost"
                :value="get(location, 'contractCost') ? `${get(location, 'contractCost')} ${currencySymbol}` : null"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Contract cost periodicity"
                class="mb-4"
              >
                <span class="text-capitalize"> {{ get(location, 'contractCostPeriodicity') }} </span>
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isModalFormOpen = true" />
      </div>
    </ui-card>
    <UpdateLocationCompany
      v-if="isModalFormOpen"
      :on-form-success="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
