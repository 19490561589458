<script>
export default {
  name: 'LocationsView',
};
</script>

<template>
  <div class="LocationsView d-flex flex-column flex-fill">
    <RouterView />
  </div>
</template>

