<script>
import map from 'lodash/map';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  GalleryComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'PreviewModal',
  components: {
    GalleryComponent,
    GenericModalComponent,
  },
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      images: state => state.detail.data.images || [],
    }),
    mappedPictures() {
      return map(this.images, ({ name, url }) => ({ label: name, src: url }));
    },
  },
};
</script>

<template>
  <GenericModalComponent
    title="Picture preview"
    :header="{ isClosable: true }"
    class="PicturePreview v1-MuiModal--adaptative"
    data-test-id="preview-modal"
    v-on="$listeners"
  >
    <template #body>
      <div class="col">
        <GalleryComponent
          :images="mappedPictures"
          :start-index="startIndex"
          data-test-id="gallery-component"
          class="h-100"
        />
      </div>
    </template>
  </GenericModalComponent>
</template>
