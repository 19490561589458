var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationContactCard" },
    [
      _c(
        "ui-card",
        {
          staticClass: "Contact w-100 position-relative d-block",
          attrs: { header: "Contact details", "data-test-id": "Contact_view" },
        },
        [
          _vm.locationStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-start" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Contact name",
                          value: _vm.get(_vm.location, "contractContact.name"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Contact phone number",
                          value: _vm.get(_vm.location, "contractContact.phone"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Contact email",
                          value: _vm.get(_vm.location, "contractContact.email"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Internal remarks",
                          value: _vm.get(_vm.location, "internalRemarks"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isModalFormOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalFormOpen
        ? _c("UpdateLocationContact", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }