var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateDescriptionModal",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.large,
          title: "Edit description",
          "data-test-id": "edit_description-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.description,
                    "is-editing": "",
                    element: "descriptions",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Name*",
                              placeholder: "Enter",
                              "data-test-id": "name-input",
                              name: "name",
                            },
                            model: {
                              value: _vm.inputs.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "name", $$v)
                              },
                              expression: "inputs.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Display address*",
                              placeholder: "Enter",
                              "data-test-id": "display_address-input",
                              name: "display_address",
                            },
                            model: {
                              value: _vm.inputs.displayAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "displayAddress", $$v)
                              },
                              expression: "inputs.displayAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 mb-4" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Address*",
                              placeholder: "Enter",
                              "data-test-id": "address-input",
                              name: "address",
                            },
                            model: {
                              value: _vm.inputs.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "address", $$v)
                              },
                              expression: "inputs.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("TranslatableFieldComponent", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: `Enter description (${_vm.language})`,
                              prefix: "description",
                              rows: "15",
                              label: "Description",
                            },
                            on: {
                              "update:language": (selectedLanguage) =>
                                (_vm.language = selectedLanguage),
                            },
                            model: {
                              value: _vm.inputs.descriptionTranslations,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs,
                                  "descriptionTranslations",
                                  $$v
                                )
                              },
                              expression: "inputs.descriptionTranslations",
                            },
                          }),
                          _c("MarkdownInformation"),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "ui-alert",
                            {
                              staticClass: "d-block my-4",
                              attrs: {
                                color: _vm.COLORS.warning,
                                icon: _vm.ICONS.alertFull,
                              },
                            },
                            [
                              _vm._v(
                                " This description is visible to users in apps and on the website "
                              ),
                            ]
                          ),
                          _c("MarkdownComponent", {
                            staticClass: "mb-4 mt-3",
                            attrs: {
                              content: _vm.inputs.descriptionTranslations,
                              language: `description${_vm.upperFirst(
                                _vm.language
                              )}`,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.descriptionStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.saveLocationDescriptions },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }