<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { ContentCellComponent, EditButton } from '@/components';

import { scopes } from '../../../store/LocationsModule';
import UpdateLocationSettings from './components/UpdateLocationSettings';

export default {
  name: 'LocationSettingsCard',
  components: {
    ContentCellComponent,
    EditButton,
    UpdateLocationSettings,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
    closeModal() {
      this.isModalFormOpen = false;
    },
  },
};
</script>
<template>
  <div class="LocationSettingsCard">
    <ui-card
      header="Settings"
      class="Settings w-100 position-relative d-block"
      data-test-id="settings_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Operator"
                :value="get(location, 'csOperator')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="City"
                :value="get(location, 'city')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Status"
                :value="get(location, 'active')"
                class="mb-4"
              >
                <ui-badge
                  :color="location.active ? COLORS.success : COLORS.danger"
                  :text="location.active ? 'Active' : 'Inactive'"
                  data-test-id="status-badge"
                />
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Neighborhood"
                :value="get(location, 'neighborhood')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Available slots"
                :value="get(location, 'slotsAvailable')"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Visibility"
                class="mb-4"
              >
                <ui-badge
                  :color="location.api ? COLORS.success : GRAYSCALE.ground"
                  :text="get(location, 'api') ? 'Visible' : 'Invisible'"
                  data-test-id="visible-badge"
                />
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Open location"
                class="mb-4"
              >
                <ui-badge
                  :color="GRAYSCALE.ground"
                  :text="get(location, 'openLocation') ? 'Yes' : 'No'"
                  data-test-id="open-badge"
                />
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isModalFormOpen = true" />
      </div>
    </ui-card>
    <UpdateLocationSettings
      v-if="isModalFormOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
