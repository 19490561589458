<script>
import { mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { LOCATION_TYPES } from '@domains/Fleet/Locations/locations.const';
import {
  ContentCellComponent,
  CopyToClipboard,
  GoogleMapComponent,
  OverviewCollapse,
} from '@/components';
import { ZONE_TYPES } from '@/components/GoogleMap/v1/composable/GoogleMap.const';

export default {
  name: 'LocationDetailHeader',
  components: {
    ContentCellComponent,
    GoogleMapComponent,
    OverviewCollapse,
    CopyToClipboard,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      openDoorsStatus: state => state.openDoors.STATUS,
    }),
    isOpenDoorsButtonVisible() {
      return get(this, 'location.hasDevice') && get(this, 'location.privateDevice.id');
    },
    locationMarkers() {
      const markers = [];

      if (this.location.gpsLat && this.location.gpsLng) {
        markers.push({
          id: 'location-marker',
          position: {
            lat: parseFloat(this.location.gpsLat),
            lng: parseFloat(this.location.gpsLng),
          },
          icon: {
            url: '/images/icons/parking.svg',
          },
        });
      }
      return markers;
    },
    locationZones() {
      const coordinates = get(this.location, 'coordinates.data');
      const zones = [];

      if (this.location.radius) {
        zones.push({
          id: 'location-radius',
          type: ZONE_TYPES.circle,
          data: {
            center: {
              lat: parseFloat(this.location.gpsLat),
              lng: parseFloat(this.location.gpsLng),
            },
            radius: this.location.radius,
          },
        });
      }

      if (coordinates) {
        zones.push({
          id: 'location-zone',
          type: this.location.coordinates.type,
          data: coordinates,
        });
      }
      return zones;
    },
  },
  created() {
    this.LOCATION_TYPES = LOCATION_TYPES;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'postOpenLocationDoors',
    ]),
    sentenceCase,
    get,
    async openParkingDoors() {
      await this.postOpenLocationDoors(get(this, 'location.id'));

      if (!this.openDoorsStatus.ERROR) {
        this.$notify({
          message: 'Location doors <span class="emobg-font-weight-semibold">opened</span>',
          textAction: '',
        });
      }
    },
  },
};
</script>
<template>
  <div
    class="LocationDetailHeader"
    data-test-id="location_detail-header"
  >
    <div class="row">
      <div class="col-12">
        <OverviewCollapse id="locationOverview">
          <template #header>
            <div class="d-flex align-items-center">
              <h2 class="emobg-font-weight-semibold mr-3">
                {{ location.name }}
              </h2>
              <ui-badge
                :color="location.active ? COLORS.success : COLORS.danger"
                :text="location.active ? 'Active' : 'Inactive'"
                class="mr-2"
                data-test-id="status-badge"
              />
              <ui-badge
                :color="GRAYSCALE.ground"
                :text="location.openLocation ? 'Open' : 'Dedicated'"
                class="mr-2"
                data-test-id="open_location-badge"
              />
            </div>
          </template>
          <template #actions>
            <ui-button
              v-if="isOpenDoorsButtonVisible"
              :loading="openDoorsStatus.LOADING"
              class="ml-3"
              data-test-id="open_doors-button"
              @clickbutton="openParkingDoors"
            >
              <ui-icon
                :icon="ICONS.unlock"
                class="pr-2"
              />
              Open doors
            </ui-button>
          </template>
          <div class="pt-4">
            <div class="row">
              <div class="col-md">
                <ContentCellComponent
                  label="Operator"
                  is-on-header
                  class="mb-4"
                >
                  <p class="mb-1">
                    {{ location.csOperator }}
                  </p>
                  <p class="emobg-color-ink-light">
                    City: {{ location.city }}
                  </p>
                  <p class="emobg-color-ink-light">
                    Neighborhood: {{ location.neighborhood }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  :value="sentenceCase(location.type)"
                  label="Location type"
                  is-on-header
                  class="mb-4"
                  data-test-id="type-text"
                />
                <ContentCellComponent
                  :value="location.slotsAvailable"
                  label="Available slots"
                  is-on-header
                  class="mb-4"
                />
              </div>
              <div
                v-if="get(location, 'type') !== LOCATION_TYPES.geofence"
                class="col-md"
              >
                <ContentCellComponent
                  :value="get(location, 'contractContact.name')"
                  label="Contact name"
                  is-on-header
                  class="mb-4"
                />
                <ContentCellComponent
                  label="Contact email"
                  is-on-header
                  class="mb-4"
                >
                  <CopyToClipboard
                    v-if="get(location, 'contractContact.email')"
                    data-test-id="copy_email-button"
                  >
                    {{ get(location, 'contractContact.email') }}
                  </CopyToClipboard>
                  <template v-else>
                    {{ FALLBACK_MESSAGE.dash }}
                  </template>
                </ContentCellComponent>
                <ContentCellComponent
                  label="Contact phone number"
                  is-on-header
                  class="mb-4"
                >
                  <CopyToClipboard
                    v-if="get(location, 'contractContact.phone')"
                    data-test-id="copy_phone-button"
                  >
                    {{ get(location, 'contractContact.phone') }}
                  </CopyToClipboard>
                  <template v-else>
                    {{ FALLBACK_MESSAGE.dash }}
                  </template>
                </ContentCellComponent>
              </div>
              <div class="col-md-6">
                <div
                  class="mb-4 emobg-border-radius-small emobg-border-color-ground-light emobg-border-1"
                  style="min-width: 302px;"
                >
                  <GoogleMapComponent
                    :markers="locationMarkers"
                    :zones="locationZones"
                  />
                </div>
              </div>
            </div>
          </div>
        </OverviewCollapse>
      </div>
    </div>
  </div>
</template>
