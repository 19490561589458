var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateLocationCompany",
        attrs: {
          header: { isClosable: true },
          title: "Edit contract",
          "data-test-id": "edit_contract-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.description,
                    "is-editing": "",
                    element: "contract",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "ui-validate",
                  {
                    on: {
                      status: ({ detail }) =>
                        (_vm.isFormValid = detail.isValid),
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("MuiAlgoliaSelect", {
                            staticClass: "w-100",
                            attrs: {
                              title: (company) => company.commercial_name,
                              index: _vm.COMPANIES_INDEX,
                              filters: `cs_operator_fk:${_vm.location.csOperatorFk}`,
                              label: "Company",
                              placeholder: "Select",
                              "path-value": "id",
                              name: "operator",
                            },
                            model: {
                              value: _vm.inputs.contractCompanyFk,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "contractCompanyFk", $$v)
                              },
                              expression: "inputs.contractCompanyFk",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("MuiDatePicker", {
                            staticClass: "w-100",
                            attrs: {
                              "date-format-key": _vm.DATE_FORMAT_KEYS.date,
                              "skip-time-step": "",
                              label: "Contract start",
                              "data-test-id": "start-select",
                            },
                            model: {
                              value: _vm.inputs.contractStart,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "contractStart", $$v)
                              },
                              expression: "inputs.contractStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("MuiDatePicker", {
                            staticClass: "w-100",
                            attrs: {
                              "date-format-key": _vm.DATE_FORMAT_KEYS.date,
                              "skip-time-step": "",
                              label: "Contract end",
                              "data-test-id": "end-select",
                            },
                            model: {
                              value: _vm.inputs.contractEnd,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "contractEnd", $$v)
                              },
                              expression: "inputs.contractEnd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("ui-text-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.input",
                                value: {
                                  isPattern:
                                    _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                                },
                                expression:
                                  "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                                modifiers: { input: true },
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.inputs.contractTerminationNotice,
                              label: "Contract termination notice (months)",
                              placeholder: "Enter a value in months",
                              "data-test-id": "termination-input",
                              name: "termination",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.contractTerminationNotice = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("ui-text-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.input",
                                value: {
                                  isPattern:
                                    _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                                },
                                expression:
                                  "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n            }",
                                modifiers: { input: true },
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.inputs.contractCost,
                              label: "Contract cost",
                              placeholder: "Enter a decimal value",
                              "data-test-id": "cost-input",
                              name: "cost",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.contractCost = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("ui-select", {
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.inputs.contractCostPeriodicity,
                              label: "Contract cost periodicity",
                              placeholder: "Select",
                              "data-test-id": "periodicity-select",
                              name: "periodicity",
                            },
                            domProps: {
                              options: _vm.map(
                                _vm.PERIODICITY,
                                (option, key) => ({
                                  label: _vm.sentenceCase(key),
                                  value: option,
                                })
                              ),
                            },
                            on: {
                              selectoption: ({ detail }) =>
                                (_vm.inputs.contractCostPeriodicity = detail),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.updateStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.saveLocationCompany },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }