<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import upperFirst from 'lodash/upperFirst';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  GenericModalComponent,
  MarkdownComponent,
  MarkdownInformation,
  StoreNotificationComponent,
  TranslatableFieldComponent,
} from '@/components';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UpdateDescriptionModal',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
    MarkdownComponent,
    MarkdownInformation,
    TranslatableFieldComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      language: 'enGb',
      inputs: {
        descriptionTranslations: null,
        name: null,
        displayAddress: null,
        address: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      descriptionStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.inputs.name = this.location.name;
    this.inputs.displayAddress = this.location.displayAddress;
    this.inputs.address = this.location.address;
    this.inputs.descriptionTranslations = mapKeys(cloneDeep(this.location.descriptionTranslations), (_value, key) => `description${upperFirst(key)}`);
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    map,
    upperFirst,
    async saveLocationDescriptions() {
      const dataForRequest = {
        ...omit(cloneDeep(this.inputs), 'descriptionTranslations'),
        active: this.location.active,
        ...this.inputs.descriptionTranslations,
      };

      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: dataForRequest,
      });

      if (!this.descriptionStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Description successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.large"
    title="Edit description"
    class="UpdateDescriptionModal"
    data-test-id="edit_description-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        element="descriptions"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.name"
              v-validate="{
                isRequired: true,
              }"
              label="Name*"
              placeholder="Enter"
              data-test-id="name-input"
              name="name"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.displayAddress"
              v-validate="{
                isRequired: true,
              }"
              label="Display address*"
              placeholder="Enter"
              data-test-id="display_address-input"
              name="display_address"
              class="w-100"
            />
          </div>

          <div class="col-12 mb-4">
            <MuiInputText
              v-model="inputs.address"
              v-validate="{
                isRequired: true,
              }"
              label="Address*"
              placeholder="Enter"
              data-test-id="address-input"
              name="address"
              class="w-100"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <TranslatableFieldComponent
              v-model="inputs.descriptionTranslations"
              :placeholder="`Enter description (${language})`"
              prefix="description"
              class="w-100"
              rows="15"
              label="Description"
              @update:language="selectedLanguage => language = selectedLanguage"
            />
            <MarkdownInformation />
          </div>
          <div class="col-12">
            <ui-alert
              :color="COLORS.warning"
              :icon="ICONS.alertFull"
              class="d-block my-4"
            >
              This description is visible to users in apps and on the website
            </ui-alert>
            <MarkdownComponent
              :content="inputs.descriptionTranslations"
              :language="`description${upperFirst(language)}`"
              class="mb-4 mt-3"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="descriptionStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationDescriptions"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
