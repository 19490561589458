var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationDeviceCard" },
    [
      _vm.get(_vm.location, "type") === _vm.LOCATION_TYPES.parking
        ? _c(
            "ui-card",
            {
              staticClass: "Device w-100 position-relative d-block",
              attrs: { header: "Device", "data-test-id": "device_view" },
            },
            [
              _vm.locationStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-start",
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: { label: "Device status" },
                            },
                            [
                              _c("ui-badge", {
                                attrs: {
                                  color: _vm.get(
                                    _vm.location,
                                    "privateDevice.active"
                                  )
                                    ? _vm.COLORS.success
                                    : _vm.GRAYSCALE.ground,
                                  text: _vm.get(
                                    _vm.location,
                                    "privateDevice.active"
                                  )
                                    ? "Active"
                                    : "Inactive",
                                  "data-test-id": "status-badge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c("ContentCellComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              label: "Phone",
                              value: _vm.get(
                                _vm.location,
                                "privateDevice.phone"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c("ContentCellComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              label: "Serial number",
                              value: _vm.get(
                                _vm.location,
                                "privateDevice.serialNumber"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("EditButton", {
                    on: {
                      click: function ($event) {
                        _vm.isModalFormOpen = true
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isModalFormOpen
        ? _c("UpdateLocationDevice", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }