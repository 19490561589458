<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'DeleteLocationModal',
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    onDeleteSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      deleteLocationStatus: state => state.deleteLocation.STATUS,
    }),
  },
  methods: {
    async removeLocation() {
      await this.deleteLocation(this.location.id);
      this.$emit('closeModal');
      if (!this.deleteLocationStatus.ERROR) {
        this.$notify({
          message: 'Location successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onDeleteSuccess();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'deleteLocation',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteLocation"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="`${location.name}, ${location.city}`"
        title="Are you sure you want to delete this location?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteLocationStatus.LOADING"
        @click="removeLocation"
      />
    </template>
  </GenericModalComponent>
</template>
