<script>
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import LocationDescriptionCard from './Description/LocationDescriptionCard';
import LocationPicturesCard from './Pictures/LocationPicturesCard';
import LocationAddressCard from './Address/LocationAddressCard';
import LocationDeviceCard from './Device/LocationDeviceCard';
import LocationSettingsCard from './Settings/LocationSettingsCard';
import LocationContactCard from './Contact/LocationContactCard';
import LocationCompanyCard from './Company/LocationCompanyCard';
import { LOCATION_TYPES } from '../../locations.const';

export default {
  name: 'LocationSetupView',
  components: {
    LocationAddressCard,
    LocationPicturesCard,
    LocationDescriptionCard,
    LocationDeviceCard,
    LocationSettingsCard,
    LocationContactCard,
    LocationCompanyCard,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
    }),
  },
  created() {
    this.LOCATION_TYPES = LOCATION_TYPES;
  },
};
</script>
<template>
  <div class="LocationSetupView">
    <h1 class="mb-2">
      Setup
    </h1>

    <div class="LocationSetupView__sections">
      <LocationSettingsCard />
      <LocationAddressCard />
      <LocationDeviceCard />
      <LocationDescriptionCard />
      <LocationPicturesCard />
      <template v-if="location.type !== LOCATION_TYPES.geofence">
        <LocationContactCard />
        <LocationCompanyCard />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "/node_modules/@emobg/sass/bootstrap/variables";

.LocationSetupView {
  &__sections > * {
    margin-bottom: map-get($spacers, 4);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
