<script>
import moment from 'moment-timezone';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';

import { MuiDatePicker, Validate } from '@emobg/vue-base';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import { DATE_FORMAT, DATE_FORMAT_KEYS, sentenceCase } from '@emobg/web-utils';

import { PERIODICITY } from '@domains/Fleet/Locations/locations.const';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';

import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UpdateLocationCompany',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiDatePicker,
    StoreNotificationComponent,
  },
  props: {
    onFormSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        contractCompanyFk: null,
        contractStart: null,
        contractEnd: null,
        contractTerminationNotice: null,
        contractCost: null,
        contractCostPeriodicity: PERIODICITY.month,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      updateStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    dataForRequest() {
      const data = cloneDeep(this.inputs);
      data.active = this.location.active;
      data.contractStart = data.contractStart ? moment(data.contractStart).format(DATE_FORMAT.dob) : null;
      data.contractEnd = data.contractEnd ? moment(data.contractEnd).format(DATE_FORMAT.dob) : null;

      return data;
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PERIODICITY = PERIODICITY;
    this.COMPANIES_INDEX = ALGOLIA_INDEXES.companies;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.inputs.contractCompanyFk = get(this, 'location.contractCompanyFk');
    this.inputs.contractStart = get(this, 'location.contractStart') ? moment(get(this, 'location.contractStart')) : null;
    this.inputs.contractEnd = get(this, 'location.contractEnd') ? moment(get(this, 'location.contractEnd')) : null;
    this.inputs.contractCost = get(this, 'location.contractCost');
    this.inputs.contractTerminationNotice = get(this, 'location.contractTerminationNotice');
    this.inputs.contractCostPeriodicity = get(this, 'location.contractCostPeriodicity', PERIODICITY.month);
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    map,
    sentenceCase,
    async saveLocationCompany() {
      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: {
          ...this.dataForRequest,
        },
      });

      if (!this.updateStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Company successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.onFormSuccess();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Edit contract"
    class="UpdateLocationCompany"
    data-test-id="edit_contract-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        element="contract"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <ui-validate @status="({ detail }) => isFormValid = detail.isValid">
        <div class="row">
          <div class="col-6 mb-3">
            <MuiAlgoliaSelect
              v-model="inputs.contractCompanyFk"
              :title="company => company.commercial_name"
              :index="COMPANIES_INDEX"
              :filters="`cs_operator_fk:${location.csOperatorFk}`"
              label="Company"
              placeholder="Select"
              class="w-100"
              path-value="id"
              name="operator"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiDatePicker
              v-model="inputs.contractStart"
              :date-format-key="DATE_FORMAT_KEYS.date"
              skip-time-step
              label="Contract start"
              data-test-id="start-select"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiDatePicker
              v-model="inputs.contractEnd"
              :date-format-key="DATE_FORMAT_KEYS.date"
              skip-time-step
              label="Contract end"
              data-test-id="end-select"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-3">
            <ui-text-input
              v-validate.input="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              :value="inputs.contractTerminationNotice"
              label="Contract termination notice (months)"
              placeholder="Enter a value in months"
              data-test-id="termination-input"
              name="termination"
              class="w-100"
              @changevalue="({ detail }) => inputs.contractTerminationNotice = detail"
            />
          </div>
          <div class="col-6 mb-3">
            <ui-text-input
              v-validate.input="{
                isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
              }"
              :value="inputs.contractCost"
              label="Contract cost"
              placeholder="Enter a decimal value"
              data-test-id="cost-input"
              name="cost"
              class="w-100"
              @changevalue="({ detail }) => inputs.contractCost = detail"
            />
          </div>

          <div class="col-6 mb-3">
            <ui-select
              :value="inputs.contractCostPeriodicity"
              :options.prop="map(PERIODICITY, (option, key) => ({ label: sentenceCase(key), value: option }))"
              label="Contract cost periodicity"
              placeholder="Select"
              data-test-id="periodicity-select"
              name="periodicity"
              class="w-100"
              @selectoption="({ detail }) => inputs.contractCostPeriodicity = detail"
            />
          </div>
        </div>
      </ui-validate>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />
        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="updateStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationCompany"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
