var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateLocationSettings",
        attrs: {
          header: { isClosable: true },
          title: "Edit settings",
          "data-test-id": "edit_settings-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.description,
                    "is-editing": "",
                    "use-message": "",
                    element: "settings",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1 d-block",
                            attrs: {
                              value: _vm.inputs.active,
                              text: _vm.inputs.active ? "Active" : "Inactive",
                              label: "Status",
                              "data-test-id": "active-toggle",
                              name: "active",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.active = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (operator) => operator.name,
                              index: _vm.ALGOLIA_INDEXES.csOperators,
                              filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                              label: "CS Operator*",
                              placeholder: "Select",
                              "path-value": "id",
                              name: "operator",
                            },
                            model: {
                              value: _vm.inputs.csOperatorFk,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "csOperatorFk", $$v)
                              },
                              expression: "inputs.csOperatorFk",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (city) => city.name,
                              index: _vm.ALGOLIA_INDEXES.cities,
                              filters: _vm.getOperatorFilter({
                                index: _vm.ALGOLIA_INDEXES.cities,
                              }),
                              label: "City*",
                              placeholder: "Select",
                              "path-value": "id",
                              name: "city",
                            },
                            model: {
                              value: _vm.inputs.cityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "cityId", $$v)
                              },
                              expression: "inputs.cityId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Neighborhood",
                              placeholder: "Enter",
                              "data-test-id": "neighborhood-input",
                              name: "neighborhood",
                            },
                            model: {
                              value: _vm.inputs.neighborhood,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "neighborhood", $$v)
                              },
                              expression: "inputs.neighborhood",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Available slots",
                              placeholder: "Enter",
                              "data-test-id": "slots-input",
                              name: "slots",
                            },
                            model: {
                              value: _vm.inputs.slotsAvailable,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "slotsAvailable", $$v)
                              },
                              expression: "inputs.slotsAvailable",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1 d-block",
                            attrs: {
                              value: _vm.inputs.api,
                              text: _vm.inputs.api ? "Visible" : "Invisible",
                              label: "Visibility",
                              "data-test-id": "visible-toggle",
                              name: "visibility",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.api = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1 d-block",
                            attrs: {
                              value: _vm.inputs.openLocation,
                              text: _vm.inputs.openLocation ? "Yes" : "No",
                              label: "Open location",
                              "data-test-id": "open-toggle",
                              name: "open",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.openLocation = detail),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.updateStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.saveLocationSettings },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }