<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  DeleteButton,
  DeleteModalBodyComponent,
  DocumentPreview,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    ContentCellComponent,
    DocumentPreview,
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    picture: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      deleteMediaStatus: state => state.deleteMedia.STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'deleteLocationPicture',
    ]),
    get,
    async removePicture() {
      const mediaId = get(this, 'picture.id');
      const locationId = get(this, 'location.id');
      await this.deleteLocationPicture({ locationId, mediaId });

      if (!this.deleteMediaStatus.ERROR) {
        this.callback();
        this.$notify({
          message: 'Picture successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
      }
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    class="DeleteModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        text="It cannot be undone"
        title="Delete this picture?"
      />
      <ContentCellComponent
        :label="picture.name || ''"
        class="mt-4"
      >
        <DocumentPreview
          v-if="picture.url"
          :image="picture.url"
          :name="picture.name"
          is-download-hidden
          data-test-id="thumbnail"
        />
      </ContentCellComponent>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        data-test-id="delete-button"
        :loading="deleteMediaStatus.LOADING"
        @click="removePicture"
      />
    </template>
  </GenericModalComponent>
</template>
