<script>
import kebabCase from 'lodash/kebabCase';
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { COLORS } from '@emobg/vue-base';
import { LOCATION_TYPES } from '@domains/Fleet/Locations/locations.const';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DELAY } from '@emobg/web-utils';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils';
import { attributesToRetrieve, contentCells, locationListFacets } from './const/locationsContentCells';
import DeleteLocationModal from './components/DeleteLocationModal';
import fleet from '../../router/FleetRouterMap';

export default {
  name: 'LocationsListView',
  components: {
    DeleteLocationModal,
    MuiAlgoliaList,
    PageView,
  },
  props: {
    refreshList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isDeleteModalVisible: false,
      location: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
  },
  created() {
    this.LOCATIONS_INDEX = ALGOLIA_INDEXES.locations;
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = this.attributesToRetrieve;
    this.contentCells = contentCells;
    this.locationListFacets = locationListFacets;
    this.actions = [
      {
        label: 'Parking',
        color: COLORS.primary,
        action: () => {
          this.$router.push({ name: fleet.locations.create, params: { type: LOCATION_TYPES.parking } });
        },
      },
      {
        label: 'Geofence',
        color: COLORS.primary,
        action: () => {
          this.$router.push({ name: fleet.locations.create, params: { type: LOCATION_TYPES.geofence } });
        },
      },
      {
        label: 'Stack',
        color: COLORS.primary,
        action: () => {
          this.$router.push({ name: fleet.locations.create, params: { type: LOCATION_TYPES.stack } });
        },
      },
      {
        label: 'Street parking',
        color: COLORS.primary,
        action: () => {
          this.$router.push({ name: fleet.locations.create, params: { type: kebabCase(LOCATION_TYPES.streetParking) } });
        },
      },
    ];
    this.itemsActions = [
      {
        label: 'Delete location',
        class: 'emobg-color-danger emobg-font-weight-semibold',
        method: location => {
          this.location = location;
          this.isDeleteModalVisible = true;
        },
      },
    ];
    this.LOCATION_TYPES = LOCATION_TYPES;
  },
  mounted() {
    if (this.refreshList) {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.locations, DELAY.extraLong, () => { this.isLoading = false; });
    }
  },
  methods: {
    closeModal() {
      this.isDeleteModalVisible = false;
      this.location = null;
    },
    onFormSuccess() {
      this.closeModal();
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.locations, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="LocationsListView"
    data-test-id="locations-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Locations
      </h1>
      <ui-dropdown
        :color="COLORS.corporateSecondary"
        :text-color="GRAYSCALE.white"
        class="z-index-4"
        arrow
      >
        <p
          slot="trigger"
          class="pl-3"
        >
          Create location
        </p>
        <ui-dropdown-actions
          slot="content"
          :actions.prop="actions"
          data-test-id="dropdown-list"
        />
      </ui-dropdown>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="locations"
        :item-actions="itemsActions"
        :export-columns="contentCells"
        :facets="locationListFacets"
        :filters="getOperatorFilter()"
        :index="LOCATIONS_INDEX"
        :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
        :table-config="contentCells"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <DeleteLocationModal
      v-if="isDeleteModalVisible"
      :location="location"
      :on-delete-success="onFormSuccess"
      data-test-id="delete_location"
      @closeModal="closeModal"
    />
  </PageView>
</template>
