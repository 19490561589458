<script>
import first from 'lodash/first';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import {
  CancelButton,
  DragFileComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { FILE_VALIDATIONS } from '@/utils';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UploadPictureModal',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    DragFileComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        file: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      addMediaStatus: state => state.addMedia.STATUS,
    }),
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.scopes = scopes;
    this.FILE_VALIDATIONS = FILE_VALIDATIONS;
    this.resetState({ scopes: [scopes.addMedia] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.fleet.locations, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['postLocationPicture']),
    async uploadPicture() {
      await this.postLocationPicture({
        locationId: this.location.id,
        data: {
          file: first(this.inputs.file),
        },
      });

      if (!this.addMediaStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Picture successfully <span class="emobg-font-weight-semibold">uploaded</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Upload picture"
    class="UploadPictureModal"
    data-test-id="picture_upload-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.addMedia"
        action="upload picture"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div>
          <label class="d-block emobg-font-weight-semibold mb-1"> Picture* </label>
          <DragFileComponent
            v-model="inputs.file"
            v-validate.files="{
              isMaxSize: FILE_VALIDATIONS.isMaxSize,
              isRequired: true,
            }"
            :draggable-height="230"
            show-preview
            data-test-id="file-input"
          >
            <template slot="extraInformation">
              <span class="d-block emobg-font-x-small mt-2">
                Maximum size 10 MB (JPG, PNG)
              </span>
            </template>
          </DragFileComponent>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />
        <ui-button
          :disabled="!isFormValid"
          :loading="addMediaStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="uploadPicture"
        >
          Upload
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
