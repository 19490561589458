var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "LocationDetailView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
      attrs: { "data-test-id": "locations_details-view" },
    },
    [
      _vm.isLoading
        ? _c("ui-loader", {
            attrs: { label: "Loading location...", "data-test-id": "loader" },
          })
        : _vm.location
        ? _c(
            "div",
            [
              _c(
                "PageView",
                { staticClass: "emobg-background-color-white" },
                [
                  _c("LocationDetailHeader", {
                    attrs: { "data-test-id": "header" },
                  }),
                ],
                1
              ),
              _c("PageTabs", {
                attrs: {
                  tabs: _vm.tabs,
                  "has-overview": "",
                  "data-test-id": "tabs",
                },
              }),
              _c(
                "PageView",
                { attrs: { "is-tab-content": "" } },
                [
                  _c(
                    "Transition",
                    { attrs: { name: "page" } },
                    [_c("RouterView")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }