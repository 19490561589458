<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapState } from 'vuex';
import {
  attributesToRetrieve,
  restrictSearchableAttributes,
} from '@domains/Carsharing/Bookings/config';
import { useBookingList } from '@domains/Carsharing/Bookings/composables/bookingList.composable';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'LocationBookingsView',
  components: {
    MuiAlgoliaList,
  },
  setup() {
    const { contentCells, facets, exportColumns } = useBookingList();
    return { contentCells, facets, exportColumns };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
    }),
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.csBookingsStartDesc;
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = restrictSearchableAttributes;
    this.algoliaFilters = `location_fk:${this.location.id}`;
  },
};
</script>
<template>
  <div
    data-test-id="location_bookings-view"
    class="LocationBookingsView"
  >
    <h1 class="mb-2">
      Bookings
    </h1>
    <MuiAlgoliaList
      :index="algoliaIndex"
      :filters="algoliaFilters"
      :facets="facets"
      :table-config="contentCells"
      :export-columns="exportColumns"
      :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
