var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationDescriptionCard" },
    [
      _c(
        "ui-card",
        {
          staticClass: "Description position-relative d-block",
          attrs: { header: "Description", "data-test-id": "description_view" },
        },
        [
          _vm.locationStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-start" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          value: _vm.location.name,
                          label: "Location name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          value: _vm.location.displayAddress,
                          label: "Display address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          value: _vm.location.address,
                          label: "Address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Description" },
                        },
                        [
                          _c("MarkdownComponent", {
                            attrs: {
                              content: { en_GB: _vm.location.description },
                              language: _vm.DESCRIPTION_LANGUAGES.en_GB,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isEditDescriptionModalOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isEditDescriptionModalOpen
        ? _c("UpdateDescriptionModal", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }