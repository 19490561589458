<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import { PageTabs, PageView } from '@/components';
import fleetRoutes from '../../router/FleetRouterMap';
import LocationDetailHeader from './components/LocationDetailHeader/LocationDetailHeader';

export default {
  name: 'LocationDetailView',
  components: {
    LocationDetailHeader,
    PageTabs,
    PageView,
  },
  beforeRouteLeave(_to, _from, next) {
    this.setLocationDetailData(null);
    next();
  },
  props: {
    locationUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
    }),
  },
  watch: {
    locationUuid: {
      async handler() {
        await this.fetchLocation(this.locationUuid);
      },
      immediate: true,
    },
  },
  created() {
    this.tabs = [
      {
        label: {
          title: 'Setup',
        },
        url: fleetRoutes.locations.detail.setup,
      },
      {
        label: {
          title: 'Vehicles',
        },
        url: fleetRoutes.locations.detail.vehicles,
      },
      {
        label: {
          title: 'Bookings',
        },
        url: fleetRoutes.locations.detail.bookings,
      },
    ];
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.locations, {
      setLocationDetailData: 'setData',
    }),
    async fetchLocation() {
      this.isLoading = true;
      await this.getLocation(this.locationUuid);
      this.isLoading = false;

      if (!this.location) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>

<template>
  <div
    :class="[
      'LocationDetailView d-flex flex-column flex-fill',
      {
        'justify-content-center': isLoading,
      }
    ]"
    data-test-id="locations_details-view"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading location..."
      data-test-id="loader"
    />
    <div v-else-if="location">
      <PageView class="emobg-background-color-white">
        <LocationDetailHeader data-test-id="header" />
      </PageView>
      <PageTabs
        :tabs="tabs"
        has-overview
        data-test-id="tabs"
      />
      <PageView is-tab-content>
        <Transition name="page">
          <RouterView />
        </Transition>
      </PageView>
    </div>
  </div>
</template>
