var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationSettingsCard" },
    [
      _c(
        "ui-card",
        {
          staticClass: "Settings w-100 position-relative d-block",
          attrs: { header: "Settings", "data-test-id": "settings_view" },
        },
        [
          _vm.locationStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-start" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Operator",
                          value: _vm.get(_vm.location, "csOperator"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "City",
                          value: _vm.get(_vm.location, "city"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Status",
                            value: _vm.get(_vm.location, "active"),
                          },
                        },
                        [
                          _c("ui-badge", {
                            attrs: {
                              color: _vm.location.active
                                ? _vm.COLORS.success
                                : _vm.COLORS.danger,
                              text: _vm.location.active ? "Active" : "Inactive",
                              "data-test-id": "status-badge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Neighborhood",
                          value: _vm.get(_vm.location, "neighborhood"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Available slots",
                          value: _vm.get(_vm.location, "slotsAvailable"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-4", attrs: { label: "Visibility" } },
                        [
                          _c("ui-badge", {
                            attrs: {
                              color: _vm.location.api
                                ? _vm.COLORS.success
                                : _vm.GRAYSCALE.ground,
                              text: _vm.get(_vm.location, "api")
                                ? "Visible"
                                : "Invisible",
                              "data-test-id": "visible-badge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Open location" },
                        },
                        [
                          _c("ui-badge", {
                            attrs: {
                              color: _vm.GRAYSCALE.ground,
                              text: _vm.get(_vm.location, "openLocation")
                                ? "Yes"
                                : "No",
                              "data-test-id": "open-badge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isModalFormOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalFormOpen
        ? _c("UpdateLocationSettings", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }