var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateLocationContact",
        attrs: {
          header: { isClosable: true },
          title: "Edit contact details",
          "data-test-id": "edit_contract-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.locations,
                    scope: _vm.scopes.description,
                    "is-editing": "",
                    element: "contact details",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("MuiInputText", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Contact name",
                              placeholder: "Enter name",
                              "data-test-id": "name",
                              name: "name",
                            },
                            model: {
                              value: _vm.inputs.contractContactName,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "contractContactName", $$v)
                              },
                              expression: "inputs.contractContactName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-3" },
                        [
                          _c("PhoneInputComponent", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isValidPhone:
                                    _vm.PHONE_VALIDATIONS.isValidPhone,
                                },
                                expression:
                                  "{\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Contact phone number",
                              placeholder: "Enter phone",
                              "data-test-id": "phone",
                            },
                            model: {
                              value: _vm.inputs.contractContactPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs,
                                  "contractContactPhone",
                                  $$v
                                )
                              },
                              expression: "inputs.contractContactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 mb-3" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isEmail: true,
                                },
                                expression:
                                  "{\n              isEmail: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Contact email",
                              placeholder: "Enter email",
                              "data-test-id": "email",
                              name: "email",
                            },
                            model: {
                              value: _vm.inputs.contractContactEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs,
                                  "contractContactEmail",
                                  $$v
                                )
                              },
                              expression: "inputs.contractContactEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Internal remarks ")]
                          ),
                          _c("MuiTextarea", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: "Enter remarks",
                              name: "remarks",
                              rows: "15",
                            },
                            model: {
                              value: _vm.inputs.internalRemarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "internalRemarks", $$v)
                              },
                              expression: "inputs.internalRemarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.updateStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.saveLocationContact },
                      },
                      [_vm._v(" Save ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }