<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { toBoolean } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  GenericModalComponent,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import { PHONE_VALIDATIONS } from '@/utils';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UpdateLocationDevice',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    PhoneInputComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        serialNumber: null,
        phone: null,
        active: false,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      updateStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.inputs.serialNumber = get(this, 'location.privateDevice.serialNumber');
    this.inputs.active = toBoolean(get(this, 'location.privateDevice.active'));
    this.inputs.phone = get(this, 'location.privateDevice.phone');
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    async saveLocationDevice() {
      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: {
          id: this.location.id,
          active: this.location.active,
          type: this.location.type,
          privateDevice: this.inputs,
        },
      });

      if (!this.updateStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Device successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Edit device"
    class="UpdateLocationDevice"
    data-test-id="edit_device-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        element="device"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12">
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              class="mb-4 py-1 d-block"
              data-test-id="active-toggle"
              name="active"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>

          <div class="col-6">
            <PhoneInputComponent
              v-model="inputs.phone"
              v-validate="{
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Phone number"
              class="w-100"
              placeholder="Enter phone"
              data-test-id="phone"
            />
          </div>

          <div class="col-6">
            <MuiInputText
              v-model="inputs.serialNumber"
              label="Serial number"
              placeholder="Enter"
              data-test-id="serial-input"
              name="serial"
              class="w-100"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="updateStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationDevice"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
