var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationAddressCard" },
    [
      _c(
        "ui-card",
        {
          staticClass: "Address w-100 position-relative d-block",
          attrs: { header: "Address", "data-test-id": "address_view" },
        },
        [
          _vm.locationStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-start" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Internal address",
                          value: _vm.location.internalAddress,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Latitude",
                          value: _vm.location.gpsLat,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-4",
                        attrs: {
                          label: "Longitude",
                          value: _vm.location.gpsLng,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.location.radius
                    ? _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            { staticClass: "mb-4", attrs: { label: "Radius" } },
                            [_vm._v(" " + _vm._s(_vm.location.radius) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isModalFormOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalFormOpen
        ? _c("LocationAddressModal", {
            attrs: { "on-success": _vm.onFormSuccess },
            on: {
              closeModal: function ($event) {
                _vm.isModalFormOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }