<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  GenericModalComponent,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import { PHONE_VALIDATIONS } from '@/utils';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'UpdateLocationContact',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiTextarea,
    MuiInputText,
    PhoneInputComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        contractContactName: null,
        contractContactPhone: null,
        contractContactEmail: null,
        internalRemarks: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      updateStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.inputs.contractContactName = get(this, 'location.contractContact.name');
    this.inputs.contractContactPhone = get(this, 'location.contractContact.phone');
    this.inputs.contractContactEmail = get(this, 'location.contractContact.email');
    this.inputs.internalRemarks = get(this, 'location.internalRemarks');
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    async saveLocationContact() {
      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: {
          active: this.location.active,
          ...this.inputs,
        },
      });

      if (!this.updateStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Contact successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Edit contact details"
    class="UpdateLocationContact"
    data-test-id="edit_contract-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        element="contact details"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.contractContactName"
              label="Contact name"
              class="w-100"
              placeholder="Enter name"
              data-test-id="name"
              name="name"
            />
          </div>

          <div class="col-6 mb-3">
            <PhoneInputComponent
              v-model="inputs.contractContactPhone"
              v-validate="{
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Contact phone number"
              class="w-100"
              placeholder="Enter phone"
              data-test-id="phone"
            />
          </div>

          <div class="col-12 mb-3">
            <MuiInputText
              v-model="inputs.contractContactEmail"
              v-validate="{
                isEmail: true,
              }"
              label="Contact email"
              class="w-100"
              placeholder="Enter email"
              data-test-id="email"
              name="email"
            />
          </div>

          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Internal remarks
            </label>
            <MuiTextarea
              v-model="inputs.internalRemarks"
              placeholder="Enter remarks"
              name="remarks"
              class="w-100"
              rows="15"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="updateStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationContact"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
