<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  GoogleLocationSelect,
  GoogleMapComponent,
  StoreNotificationComponent,
} from '@/components';
import { ZONE_TYPES } from '@/components/GoogleMap/v1/composable/GoogleMap.const';
import { POSITIVE_BUT_ZERO_PATTERN, toNumberUtil } from '@/utils';
import { TYPES } from '../../../const/locations.const';
import { scopes } from '../../../../store/LocationsModule';

export default {
  name: 'LocationAddressModal',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    ContentCellComponent,
    GenericModalComponent,
    GoogleLocationSelect,
    GoogleMapComponent,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        internalAddress: null,
        gpsLat: null,
        gpsLng: null,
        radius: null,
        coordinates: {},
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      addressStatus: state => state.description.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    isGeofence() {
      return this.location.type === TYPES.geofence;
    },
    locationMarkers() {
      const markers = [];

      if (this.inputs.gpsLat && this.inputs.gpsLng) {
        markers.push({
          id: 'location-marker',
          position: {
            lat: parseFloat(this.inputs.gpsLat),
            lng: parseFloat(this.inputs.gpsLng),
          },
          icon: {
            url: '/images/icons/parking.svg',
          },
        });
      }
      return markers;
    },
    locationZones() {
      const coordinates = get(this.inputs.coordinates, 'data');
      const zones = [];

      if (isNumber(this.inputs.radius) && this.inputs.gpsLat && this.inputs.gpsLng) {
        zones.push({
          id: 'location-radius',
          type: ZONE_TYPES.circle,
          data: {
            center: {
              lat: parseFloat(this.inputs.gpsLat),
              lng: parseFloat(this.inputs.gpsLng),
            },
            radius: this.inputs.radius,
          },
          isEditable: false,
        });
      }

      if (coordinates) {
        zones.push({
          id: 'location-geofence-zone',
          type: this.inputs.coordinates.type,
          data: coordinates,
        });
      }
      return zones;
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ZONE_TYPES = ZONE_TYPES;
    this.POSITIVE_BUT_ZERO_PATTERN = POSITIVE_BUT_ZERO_PATTERN;

    if (this.location) {
      this.inputs.internalAddress = this.location.internalAddress;
      this.inputs.gpsLat = this.location.gpsLat;
      this.inputs.gpsLng = this.location.gpsLng;
      this.inputs.radius = this.location.radius;
      this.inputs.coordinates = cloneDeep(this.location.coordinates);
    }

    this.originalInputs = cloneDeep(this.inputs);

    this.setRadiusDebounced = debounce(
      radius => { this.inputs.radius = toNumberUtil(radius); },
      DELAY.short,
    );
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['putLocationDescriptions']),
    async saveLocationAddress() {
      await this.putLocationDescriptions({
        locationId: this.location.id,
        data: {
          type: this.location.type,
          active: this.location.active,
          ...this.inputs,
          coordinates: this.inputs.coordinates ? [this.inputs.coordinates] : null,
        },
      });

      if (!this.addressStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Address successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.onSuccess();
      }
    },
    setLatLng(place) {
      this.inputs.gpsLat = place.geometry.location.lat();
      this.inputs.gpsLng = place.geometry.location.lng();
      const isLocationInsideArea = get(this, 'inputs.coordinates.data')
        ? this.$GoogleMaps.instance.maps.geometry.poly.containsLocation({ lat: this.inputs.gpsLat, lng: this.inputs.gpsLng },
          new this.$GoogleMaps.instance.maps.Polygon({ paths: get(this, 'inputs.coordinates.data') }))
        : false;
      if (!isLocationInsideArea) {
        this.inputs.coordinates = null;
      }
    },
    createZone(zone) {
      if (this.isGeofence) {
        this.inputs.coordinates = zone;
      }
    },
    updateZone(zone) {
      if (this.isGeofence) {
        this.inputs.coordinates.data = zone.data;
        return;
      }

      this.inputs.radius = get(zone, 'data.radius');
    },
    deleteZone() {
      if (this.isGeofence) {
        this.inputs.coordinates = null;
        return;
      }

      this.inputs.radius = null;
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.large"
    title="Edit address"
    class="LocationAddressModal"
    data-test-id="location_address-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.locations"
        :scope="scopes.description"
        is-editing
        element="address"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12 mb-4">
            <GoogleLocationSelect
              v-model="inputs.internalAddress"
              v-validate="{
                isRequired: true,
              }"
              label="Internal address*"
              placeholder="Enter an address"
              data-test-id="internal_address-input"
              name="name"
              class="w-100"
              @update:place="setLatLng"
            />
          </div>

          <div :class="[`col-${isGeofence ? '6' : '4'} mb-4`]">
            <ContentCellComponent
              label="Latitude"
              :value="inputs.gpsLat"
            />
          </div>

          <div :class="[`col-${isGeofence ? '6' : '4'} mb-4`]">
            <ContentCellComponent
              label="Longitude"
              :value="inputs.gpsLng"
            />
          </div>

          <div
            v-if="!isGeofence"
            class="col-4 mb-4"
          >
            <MuiInputText
              v-validate="{
                isPattern: {
                  pattern: POSITIVE_BUT_ZERO_PATTERN,
                  message: 'Only numbers above 0 allowed',
                },
              }"
              :model-value="inputs.radius"
              label="Radius"
              placeholder="Enter radius"
              data-test-id="radius-input"
              name="address"
              class="w-100"
              @input="setRadiusDebounced"
            />
          </div>

          <div class="col-12">
            <GoogleMapComponent
              :markers="locationMarkers"
              :zones="locationZones"
              :zone-types="isGeofence ? [ZONE_TYPES.polygon, ZONE_TYPES.rectangle] : []"
              editable
              @create:zone="createZone"
              @update:zone="updateZone"
              @delete:zone="deleteZone"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="addressStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveLocationAddress"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
