var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LocationPicturesCard" },
    [
      _c(
        "ui-card",
        {
          staticClass: "Pictures w-100 d-block",
          attrs: { "data-test-id": "pictures_card" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center",
            },
            [
              _c(
                "h3",
                {
                  staticClass: "emobg-color-ink emobg-font-weight-semibold",
                  attrs: { "data-test-id": "title" },
                },
                [_vm._v(" Pictures ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { face: _vm.FACES.outline },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isAddModalOpen = true
                    },
                  },
                },
                [_vm._v(" Upload picture ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.imagesStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c("TableComponent", {
                staticClass: "mt-3",
                attrs: {
                  schema: _vm.pictureSchema,
                  data: _vm.images,
                  "row-actions": _vm.pictureRowActions,
                  "empty-label": "No pictures yet",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isPreviewModalOpen
        ? _c("PreviewModal", {
            attrs: { "start-index": _vm.startIndex },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isAddModalOpen
        ? _c("UploadPictureModal", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("DeleteModal", {
            attrs: {
              picture: _vm.pictureToDelete,
              callback: _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }