<script>
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DESCRIPTION_LANGUAGES } from '@domains/Carsharing/Vehicles/Details/Setup/components/const/editVehicleComponents';
import { ContentCellComponent, EditButton, MarkdownComponent } from '@/components';

import UpdateDescriptionModal from './components/UpdateDescriptionModal';

export default {
  name: 'LocationDescriptionCard',
  components: {
    UpdateDescriptionModal,
    EditButton,
    ContentCellComponent,
    MarkdownComponent,
  },
  data() {
    return {
      isEditDescriptionModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.DESCRIPTION_LANGUAGES = DESCRIPTION_LANGUAGES;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    closeModal() {
      this.isEditDescriptionModalOpen = false;
    },
    async onFormSuccess() {
      this.getLocation(this.location.uuid);
    },
  },
};
</script>
<template>
  <div class="LocationDescriptionCard">
    <ui-card
      header="Description"
      class="Description position-relative d-block"
      data-test-id="description_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                :value="location.name"
                label="Location name"
                class="mb-4"
              />
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                :value="location.displayAddress"
                label="Display address"
                class="mb-4"
              />
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                :value="location.address"
                label="Address"
                class="mb-4"
              />
            </div>

            <div class="col-md-8">
              <ContentCellComponent
                label="Description"
                class="mb-4"
              >
                <MarkdownComponent
                  :content="{ en_GB: location.description }"
                  :language="DESCRIPTION_LANGUAGES.en_GB"
                />
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isEditDescriptionModalOpen = true" />
      </div>
    </ui-card>
    <UpdateDescriptionModal
      v-if="isEditDescriptionModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
