<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  attributesToRetrieve,
  contentCells,
  facets,
} from './config/LocationVehiclesConfig';

export default {
  name: 'LocationVehiclesView',
  components: {
    MuiAlgoliaList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
    }),
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.vehicleLocation;
    this.attributesToRetrieve = attributesToRetrieve;
    this.facets = facets;
    this.contentCells = contentCells;
    const now = moment();
    const startOfToday = now.startOf('day').format('X');
    const endOfToday = now.endOf('day').format('X');
    this.algoliaFilters = `location_id:${this.location.id} AND start_ts <= ${endOfToday} AND end_ts >= ${startOfToday}`;
  },
};
</script>
<template>
  <div
    data-test-id="location_vehicles-view"
    class="LocationVehiclesView"
  >
    <h1 class="mb-2">
      Vehicles
    </h1>
    <MuiAlgoliaList
      :index="algoliaIndex"
      :filters="algoliaFilters"
      :facets="facets"
      :table-config="contentCells"
      :export-columns="contentCells"
      :query-parameters="{ attributesToRetrieve }"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
