<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';

import { scopes } from '../../../store/LocationsModule';
import LocationAddressModal from './components/LocationAddressModal';

export default {
  name: 'LocationAddressCard',
  components: {
    ContentCellComponent,
    EditButton,
    LocationAddressModal,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      location: state => state.detail.data,
      locationStatus: state => state.detail.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.locations, [
      'getLocation',
    ]),
    async onFormSuccess() {
      await this.getLocation(this.location.uuid);
    },
  },
};
</script>
<template>
  <div class="LocationAddressCard">
    <ui-card
      header="Address"
      class="Address w-100 position-relative d-block"
      data-test-id="address_view"
    >
      <ui-loader
        v-if="locationStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Internal address"
                :value="location.internalAddress"
                class="mb-4"
              />
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Latitude"
                :value="location.gpsLat"
                class="mb-4"
              />
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Longitude"
                :value="location.gpsLng"
                class="mb-4"
              />
            </div>
            <div
              v-if="location.radius"
              class="col-md-6 col-lg-3"
            >
              <ContentCellComponent
                label="Radius"
                class="mb-4"
              >
                {{ location.radius }}
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isModalFormOpen = true" />
      </div>
    </ui-card>
    <LocationAddressModal
      v-if="isModalFormOpen"
      :on-success="onFormSuccess"
      @closeModal="isModalFormOpen = false"
    />
  </div>
</template>
